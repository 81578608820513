import React from "react";
import "../Styles/Intro.css";
import { Link } from "react-scroll";
import Typewriter from "typewriter-effect";
import ScrollAnimation from '../Services/ScrollAnimation';

const Intro = () => {

    const TypewriterEffect = () => {
        return (
            <Typewriter
            options={{
                delay: 60,
            }}
            onInit={(typewriter) => {
                typewriter
                    .typeString("{Hi!}")
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString("{Need help to develop your software?}")
                    .start();
                    
                }}
            />
        );
    };

    return (
        <div className="wrapper-intro">
            <div className="grid-container">
                <div className="right-column">
                    <ScrollAnimation>
                        <div className="intro-container">
                            <div className="intro-content">
                                <div className="typewriter-title">
                                    <TypewriterEffect />    
                                </div>
                                <h2>Software Engineer</h2>
                                <p>
                                    Hi, I'm a software engineering student at Aarhus University. I'm passionate about developing software. 
                                    <br></br><br></br>Check out my projects and feel free to contact me.
                                </p>
                            </div>
                            
                            <div className="intro buttons intro-margin">
                                <Link to="aboutPage" smooth={true} duration={500} offset={-10}>
                                    <button className="intro-button-aboutMe" id="aboutMe-intro">About Me</button>
                                </Link> 
                                <Link to="projectsPage" smooth={true} duration={500} offset={-10}>
                                    <button className="intro-button-projects" id="projects-intro">My Work</button>
                                </Link>
                                <Link to="contactPage" smooth={true} duration={500} offset={-10}>
                                    <button className="intro-button-contact" id="contact-intro">Contact</button>
                                </Link>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>   
            </div>    
        </div>
    );
}

export default Intro;