import React, { useRef, useEffect } from "react";
import "../Styles/Skills.css";
import ScrollAnimation from '../Services/ScrollAnimation';


const Skills = () => {
    const containerRef = useRef(null);
    const scrollAmount = 300;

    useEffect(() => {
        const interval = setInterval(() => {
            if (containerRef.current) {
                const maxScrollLeft = containerRef.current.scrollWidth - containerRef.current.clientWidth;
                if (containerRef.current.scrollLeft >= maxScrollLeft) {
                    containerRef.current.scrollLeft = 0;
                } else {
                    containerRef.current.scrollBy({
                        top: 0,
                        left: scrollAmount,
                        behavior: 'smooth'
                    });
                }
            }
        }, 3000); 

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="wrapper-skills" id="skillsPage">
            <div className="skill-section">
                <ScrollAnimation>
                <h1 className="heading">Experience</h1>
                <p className="skills-info">Here are some of the skills I have experienced over the years.<br></br> The colors indicate my level of experience.</p>
                <div className="skill-level-indicators">
                    <div className="skill-level-indicator">
                        <div className="circle beginner"></div>
                        <span>I'm just starting out with this.</span>
                    </div>
                    <div className="skill-level-indicator">
                        <div className="circle advanced"></div>
                        <span>I'm doing well at this.</span>
                    </div>
                    <div className="skill-level-indicator">
                        <div className="circle expert"></div>
                        <span>I feel comfortable doing this.</span>
                    </div>
                    <div className="skill-level-indicator">
                        <div className="circle master"></div>
                        <span>I feel at home doing this.</span>
                    </div>
                </div>
                </ScrollAnimation>
                <div className="skills-container" ref={containerRef}>
                    {/* Programming languages */}
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level home">Intermediately</div>
                        <h1 className="skill-name">C</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic2} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">C++</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic3} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">C#</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic4} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">JavaScript</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">TypeScript</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic2} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">SQL</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic3} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Homely</div>
                        <h1 className="skill-name">HTML</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic4} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Intermediately</div>
                        <h1 className="skill-name">CSS</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" />   */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">MAUI</h1>
                    </div>
                    {/* Frameworks */}
                    <div className="skill-card">
                    {/* <img src={graphic2} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Homely</div>
                        <h1 className="skill-name">React</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic3} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">React Native</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic4} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">Next.js</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Intermediately</div>
                        <h1 className="skill-name">.NET</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic2} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Intermediately</div>
                        <h1 className="skill-name">NSubstitute</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic3} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Intermediately</div>
                        <h1 className="skill-name">Entity Framework Core</h1>
                    </div>
                    {/* Database & Tools */}
                    <div className="skill-card">
                        {/* <img src={graphic4} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Intermediately</div>
                        <h1 className="skill-name">Firebase</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">MySQL</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic2} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Intermediately</div>
                        <h1 className="skill-name">Docker</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic3} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">Azure</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic4} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">Postman</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">MongoDB</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic2} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Intermediately</div>
                        <h1 className="skill-name">Git</h1>
                    </div>
                    {/* Design Tools */}
                    <div className="skill-card">
                        {/* <img src={graphic3} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">Rive</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic4} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">Rotato</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Beginner</div>
                        <h1 className="skill-name">Adobe Illustrator</h1>
                    </div>
                    {/* Project Management */}
                    <div className="skill-card">
                        {/* <img src={graphic2} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">SCRUM</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic3} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">Trello</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic4} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">Agile Models</h1>
                    </div>
                    {/* IDE's */}
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Homely</div>
                        <h1 className="skill-name">WebStorm</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic2} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Homely</div>
                        <h1 className="skill-name">Rider</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic3} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Intermediately</div>
                        <h1 className="skill-name">Xcode</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic4} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">Visual Studio</h1>
                    </div>
                    <div className="skill-card">
                        {/* <img src={graphic1} alt="graphic" className="skill-img" /> */}
                        <div className="skill-level">Comfortable</div>
                        <h1 className="skill-name">VS Code</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skills;
